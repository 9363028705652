import React, {
  useEffect,
  useState,
} from 'react';

import { useLocation } from 'react-router-dom';

import { getCountryFunction } from '../../services/Continents';
import CitiesAreas from '../destinations/CitiesAreas';
import CountryTime from '../destinations/CountryTime';
import CountryWeather from '../destinations/CountryWeather';
import CoupleReasons from '../destinations/CoupleReasons';
import DestinationDetailsHero from '../destinations/DestinationDetailsHero';
import FunFacts from '../destinations/FunFacts';
import PopularLocalFoods from '../destinations/PopularLocalFoods';

const DestinationDetails = () => {
  const [data, setContinents] = useState([]);
  const [name, setName] = useState('');

  const location = useLocation();

  useEffect(() => {
    const getContinentNameFromUrl = () => {
      const urlParts = location.pathname.split('/');
      const continentName = urlParts[urlParts.length - 1]; // Last part of the URL
      return continentName;
    };

    const continentName = getContinentNameFromUrl();
    setName(continentName);
  }, [location]);

  useEffect(() => {
    if (name) {
      restionData(name);
    }
  }, [name]);

  

  const restionData = async (name) => {
    try {
      const res = await getCountryFunction(name);
      console.log(res.data.data);
      setContinents(res.data.data);
    //   setContinentsData(res.data.data.data[0].title);
    //   setContinentsName(res.data.data.data[0].name);
    //   setContinentsImage(res.data.data.data[0].mainImage);
    } catch (error) {
      console.error('Error fetching region data:', error);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <DestinationDetailsHero data={data} />
      <CountryTime data={data} />
      <CountryWeather data={data} />
      <CitiesAreas data={data} />
      <FunFacts data={data} />
      <PopularLocalFoods data={data} />
      <CoupleReasons data={data} />
    </>
  );
};

export default DestinationDetails;
