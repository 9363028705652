import React, { useEffect } from "react";
import AboutUsHero from "../aboutusPage/AboutUsHero";
import WhyChooseOryon from "../aboutusPage/WhyChooseOryon";

const AboutUsPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="">
      <AboutUsHero />
      <WhyChooseOryon />
    </div>
  );
};

export default AboutUsPage;
