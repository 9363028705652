import './Destination.css';

import React, {
  useEffect,
  useState,
} from 'react';

import currency from '../../assets/images/currency-icon.svg';
import Lang from '../../assets/images/lang-icon.svg';
import {
  getCurrencyConvertFunction,
  getCurrencyFunction,
} from '../../services/Continents';

const DestinationDetailsHero = ({ data }) => {
  const [Currency, setCurrency] = useState({});
  const [selectedCurrency, setSelectedCurrency] = useState('AED'); // Default value can be any currency code
  const [selectedCurrency2, setSelectedCurrency2] = useState('AED'); // Default value can be any currency code
  const [selectedCurrency3, setSelectedCurrency3] = useState(Number); // Default value can be any currency code
  const [CurrencyData, setCurrencyData] = useState({}); // Default value can be any currency code
  const [ress, setRes] = useState(Number); // Default value can be any currency code
    const handleCurrencyChange = (event) => {
        setSelectedCurrency(event.target.value);
        setCurrencyData(selectedCurrency)
    };
    const handleCurrencyChange2 = (event) => {
        setSelectedCurrency2(event.target.value);
        setCurrencyData(selectedCurrency2)
    };

    const handleCurrencyChange3 = (event) => {
        setSelectedCurrency3(event.target.value);
        setCurrencyData(event.target.value)
        setTimeout(() => {
            add(event.target.value)
        }, 2000);
    
    };

    const add = async (val) => {
            console.log("CurrencyData", val);
            console.log("CurrencyData111", selectedCurrency);
            console.log("CurrencyData2222", selectedCurrency2);
            const res = await getCurrencyConvertFunction(selectedCurrency,selectedCurrency2,val)
            console.log(res.data,"currency convert response");
            setRes(res.data)
    }

    // console.log(selectedCurrency);
    // console.log(selectedCurrency2);
    // console.log(selectedCurrency3);
    console.log( "CurrencyData",CurrencyData);


    

  useEffect(() => {
    abcd();
  }, {});

  const abcd = async () => {
    const res = await getCurrencyFunction();
    console.log(res.data.data.currencyRates);
    setCurrency(res.data.data.currencyRates);
  };

  console.log("Currency", Currency);

  return (
    <div className="custom_top_margin">
      <img className=" filter w-100"
        src={`${process.env.REACT_APP_IMAGE_URL}${data.headerImage}`}
        alt="Image"
      />
      
      <div className="container translate_middle-y">
        <div
          className="col-md-9 mx-auto rounded-3 "
          style={{ backgroundColor: "#FFFAF0" }}
        >
          <div className="row px-md-5 px-3 pt-md-5 pt-3">
            <div className="col-md-4 mt-4 mt-md-0 justify-content-center col-sm-6 d-flex align-items-center">
              <img 
                className="custom_width"
                src={`${process.env.REACT_APP_IMAGE_URL}${data.flagImg}`}
                alt="country flag"
              />
              <span className="ff_heebo fw-normal fs_22 text-black ms-2">
                {data.name}
              </span>
            </div>
            <div className="col-md-4 mt-4 mt-md-0 justify-content-center col-sm-6 d-flex align-items-center">
              <img src={Lang} alt="lang icon" />
              <div>
                <span className="ff_heebo fw-normal  m-0 text-black ms-2">
                  Official Language
                </span>
                <p className="m-0 ff_heebo fw-bold  text-black ms-2">
                  {data.language}
                </p>
              </div>
            </div>
            <div className="col-md-4 mt-4 mt-md-0 justify-content-center col-sm-6 d-flex align-items-center">
              <img src={currency} alt="Curreny icon" />
              <div>
                <span className="ff_heebo fw-normal  m-0 text-black ms-2">
                  Currency 
                </span>
                <p className="m-0 ff_heebo fw-bold  text-black ms-2">
                  {data.currency}
                </p>
              </div>
            </div>
          </div>
          {/* curency exchange start  */}
          <div className="bg_dark_brown py-md-4 py-2 px-2 px-md-4 col-10 rounded-2 mx-auto translate-middle_y">
            <div className="row">
              <div className="col-md-6">
              <div className="d-flex flex-column align-items-center ">
                <p className="ff_inter  fs_15 fw-normal text-white">Amount</p>
                <div className="d-md-flex align-items-center">
                  <div>
                    {/* <img
                      width={50}
                      height={50}
                      className="object-fit-cover rounded-circle"
                      src={SGD}
                      alt="sgd"
                    /> */}
                    <select
                      className=" bg_dark_brown border-0 text-white fs_20 fw-noraml ff_inter"
                      name=""
                      id=""
                      value={selectedCurrency2}
                      onChange={handleCurrencyChange2}
                    >
                      {Object.entries(Currency).map(([key, value]) => (
        <option key={key} value={key}>{key}</option>
    ))}
                    </select>
                  </div>
                  <div className="ms-md-4 mt-3 mt-md-0">
                    <input
                      type="number"
                      value={selectedCurrency3}
                      onChange={handleCurrencyChange3}
                      className="bg-white border-0 p-2 custom_input_width rounded-2 text-end fw-bold  fs_21"
                      placeholder="00"
                      name='amount'
                    />
                  </div>
                </div>
                </div>
              </div>
              <div className="col-md-6 mt-4 mt-md-0">
               <div className="d-flex flex-column align-items-center ">
               <p className="ff_inter  fs_15 fw-normal ps-5 text-white">
                  Converted Amount
                </p>
                <div className="d-md-flex align-items-center">
                  <div>
                   
                    <select
                      className=" bg_dark_brown border-0 text-white fs_20 fw-noraml ff_inter"
                      name=""
                      id=""
                      value={selectedCurrency}
                    onChange={handleCurrencyChange}
                    >
                       {Object.entries(Currency).map(([key, value]) => (
        <option key={key} value={key}>{key}</option>
    ))}
                    </select>
                  </div>
                  <div className="ms-md-4 mt-3 mt-md-0">
                    <input
                      type="number"
                      className="bg-white border-0 p-2 custom_input_width rounded-2 text-end fw-bold  fs_21"
                      placeholder={ress.convertedAmount}
                    />
                  </div>
                </div>
               </div>
              </div>
            </div>
          </div>
          {/* curency exchange end  */}
        </div>
      </div>
    </div>
  );
};

export default DestinationDetailsHero;
