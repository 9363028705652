import React, { useEffect } from 'react'
import CountryHero from '../country/CountryHero'
import MakeTripe from '../country/MakeTripe'
import SignUp from '../country/SignUp'
const Country = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (
        <>
    <CountryHero/>
    <MakeTripe/>
    <SignUp/>
    </>
    )
}

export default Country