import React from "react";

const WhyChooseOryon = () => {
  return (
    <div className="py-5">
      <div className="container">
        <h4 className=" fs_30 text-center ff_heebo fw-medium color_primary">
          Why Choose Oryon
        </h4>
        <div className="px-5 mt-4">
          <ul className="ps-5">
            <li className="mt-2">
              Oryon ensures the best deals and prices, as well as
              recommendations on the ideal seasons to visit which locations, to
              ensure you have the utmost/full traveler experience. No hidden
              costs or extra fees.
            </li>
            <li className="mt-2">
              All information necessary about the covid/omicron, and other
              variants of the virus will be given together with the itinerary,
              along with any visa entry requirements if needed.
            </li>
            <li className="mt-2">
              We will be available 24/7 for you throughout your journey
            </li>
            <li className="mt-2">We’ve got you covered.</li>
            <li className="mt-2">
              Expertise • Protected • Best local travel experts/connections • No
              hidden feeds • Added Value/Benifits
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default WhyChooseOryon;
