import React, { useEffect, useState } from "react";

import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import Banner from "../../assets/images/hero_bottom_sad.png";
import hero_img1 from "../../assets/images/hero_img1.png";
import hero_img2 from "../../assets/images/hero_img2.png";
import ImgView3 from "../../assets/images/img-view-03.jpg";
import logo_img from "../../assets/images/logo_img.png";
import shed from "../../assets/images/shed.png";
import ImgView2 from "../../assets/images/slider_img.png";
import ImgView1 from "../../assets/images/slider_img1.png";
import { createFunction } from "../../services/Continents";

const HomePage = () => {
  const [formData, setformData] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    reason: "",
    desiredFromOfContact: "",
    desiredDestination: "",
    accommodationPreference: "",
    budget: "",
    flightsIncluded: "",
    departingCity: "",
    adults: "",
    children: "",
    fromLocation: "",
    toLocation: "",
    vacationBrief: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setformData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleSelectChange = (e) => {
    const { name, value } = e.target;

    setformData((prevData) => ({
      ...prevData,
      [name]: value === "true" ? true : false,
    }));
  };
  const createForm = async (e) => {
    e.preventDefault();
    console.log("zkdjzjsdoijiosjdzsdvzsd");

    const data = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      phoneNumber: formData.phoneNumber,
      email: formData.email,
      reason: formData.reason,
      desiredDestination: formData.desiredDestination,
      accommodationPreference: formData.accommodationPreference,
      budget: formData.budget,
      flightsIncluded: formData.flightsIncluded,
      departingCity: formData.departingCity,
      adults: formData.adults,
      children: formData.children,
      fromLocation: formData.fromLocation,
      toLocation: formData.toLocation,
      vacationBrief: formData.vacationBrief,
      desiredFromOfContact: formData.desiredFromOfContact,
    };

    try {
      const res = await createFunction(data);
      console.log(res);
      alert(
        "Thank you for connecting with us. We will get back to you shortly."
      );
      setformData({
        firstName: "",
        lastName: "",
        phoneNumber: "",
        email: "",
        reason: "",
        desiredDestination: "",
        accommodationPreference: "",
        budget: "",
        flightsIncluded: "",
        departingCity: "",
        adults: "",
        children: "",
        fromLocation: "",
        toLocation: "",
        desiredFromOfContact: "",
        vacationBrief: "",
      });
      window.scrollTo(0, 0);
      // createFormCallBack(res);
    } catch (error) {
      // toast.error("Failed to create lead. Please try again later.");
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Container fluid className="p-0 position-relative">
        <section className="position-relative hero_bg_image">
          <img
            src={Banner}
            className="custom_hight position-absolute bottom-0 w-100"
          />
          <div className=" w-100">
            <Container
              fluid
              className="d-flex justify-content-md-end  justify-content-center flex-column min-vh-100 flex-grow-1"
            >
              <Container>
                <Row className=" position-relative pb-md-5 mb-md-5 z-index-1">
                  <Col className="col-md-6 col-12">
                    <div className="d-flex flex-column h-100 w-100 justify-content-center">
                      <h2 className=" custom_color ff_heebo fs_41 mt-5 fw-medium">
                        What we stand for
                      </h2>
                      <div className="text-black ff_heebo fs_20">
                        Serenity - Freedom - Energy - Diversity
                      </div>
                      <div className="text-black ff_heebo fs_14">
                        Mindfulness Awakening Togetherness
                      </div>
                    </div>
                  </Col>
                  <Col className="col-md-6 col-12 mt-5 mt-md-0">
                    <Container fluid>
                      <Row className="">
                        <Col className="col-sm-6 ">
                          <div className="rounded overflow-hidden">
                            <img src={hero_img1} className="img-fluid" />
                          </div>
                        </Col>
                        <Col className="pt-md-5 col-sm-6  ">
                          <div className="rounded overflow-hidden">
                            <img src={hero_img2} className="img-fluid" />
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                </Row>
              </Container>
            </Container>
          </div>
        </section>

        <section className="bg-section image_form pb-md-5 position-relative mb-4">
          <Container className="">
            <Row>
              <Col className="col-12 col-md-6">
                <div className="fs_40 fw-medium ff_heebo">
                  Planning your vacation has never been easier !
                </div>
                <div className="fs_16 fw-light ff_heebo">
                  To get started, fill out the form and let one of our agents
                  get in-touch with you
                </div>
              </Col>
              <Col className="col-12 mt-4 mt-md-0 col-md-6">
                <div className="rounded-4 p-3 bg-white">
                  <Container fluid>
                    <div className="fs_33 text-brown ff_heebo mb-2 font-medium">
                      Travel with purpose
                    </div>
                  </Container>
                  <form onSubmit={createForm}>
                    <Container fluid>
                      <Row className="mb-2">
                        <Col className="col-12 col-md-6 mt-3">
                          <input
                            required
                            type="text"
                            className="form-control py-3 px-3 rounded-0 ff_heebo border-0 bg-theme-primary"
                            placeholder="First Name"
                            value={formData?.firstName}
                            onChange={handleInputChange}
                            name="firstName"
                          />
                        </Col>
                        <Col className="col-12 col-md-6 mt-3">
                          <input
                            required
                            type="text"
                            className="form-control rounded-0 ff_heebo py-3 px-3 border-0 bg-theme-primary"
                            placeholder="Last Name"
                            value={formData?.lastName}
                            onChange={handleInputChange}
                            name="lastName"
                          />
                        </Col>
                      </Row>

                      <Row className="mb-2">
                        <Col className="col-12 col-md-6 mt-3">
                          <input
                            required
                            type="number"
                            className="form-control rounded-0 ff_heebo py-3 px-3 border-0 bg-theme-primary"
                            placeholder="Phone"
                            value={formData?.phoneNumber}
                            onChange={handleInputChange}
                            name="phoneNumber"
                          />
                        </Col>
                        <Col className="col-12 col-md-6 mt-3">
                          <input
                            required
                            type="email"
                            className="form-control rounded-0 ff_heebo py-3 px-3 border-0 bg-theme-primary"
                            placeholder="Email"
                            value={formData?.email}
                            onChange={handleInputChange}
                            name="email"
                          />
                        </Col>
                      </Row>

                      <Row className="mb-2">
                        <Col className="col-12 col-md-6 mt-3">
                          {/* <input
                            required
                            type="text"
                            className="form-control rounded-0 ff_heebo py-3 px-3 border-0 bg-theme-primary"
                            placeholder="Reason for travel"
                            value={formData?.reason}
                            onChange={handleInputChange}
                            name="reason"
                          /> */}
                          <select
                            name="reason"
                            id="form-field-field_94f0f16"
                            className="elementor-field-textual elementor-size-sm w-100 rounded-0 fs_13 color_gary ff_heebo py-3 px-3 border-0 bg-theme-primary"
                            required="required"
                            aria-required="true"
                            value={formData?.reason}
                            onChange={handleInputChange}
                          >
                            <option
                              className=" color_gary fs_13"
                              value="Reason for Travel"
                            >
                              Reason for Travel
                            </option>
                            <option className=" color_gary fs_13" value="Work">
                              Work
                            </option>
                            <option
                              className=" color_gary fs_13"
                              value="Leisure"
                            >
                              Leisure
                            </option>
                            <option className=" color_gary fs_13" value="Other">
                              Other
                            </option>
                          </select>
                        </Col>
                        <Col className="col-12 col-md-6 mt-3">
                          <input
                            required
                            type="text"
                            className="form-control rounded-0 ff_heebo py-3 px-3 border-0 bg-theme-primary"
                            placeholder="Desired Destination"
                            value={formData?.nadesiredDestinationme}
                            onChange={handleInputChange}
                            name="desiredDestination"
                          />
                        </Col>
                      </Row>

                      <Row className="mb-2">
                        <Col className="col-12 mt-3">
                          {/* <input
                            required
                            type="text"
                            className="form-control rounded-0 ff_heebo py-3 px-3 border-0 bg-theme-primary"
                            placeholder="Desired Form Of Contact"
                            value={formData?.flightsIncluded}
                            onChange={handleInputChange}
                            name="flightsIncluded"
                          /> */}

                          <select
                            id="form-field-field_8e1bf94"
                            className="elementor-field-textual elementor-size-sm w-100 rounded-0 fs_13 color_gary ff_heebo py-3 px-3 border-0 bg-theme-primary"
                            required
                            aria-required="true"
                            value={formData?.desiredFromOfContact}
                            onChange={handleInputChange}
                            name="desiredFromOfContact"
                          >
                            <option
                              className=" color_gary fs_13"
                              value="Desired Form Of Contact"
                            >
                              Desired Form Of Contact
                            </option>
                            <option className=" color_gary fs_13" value="Email">
                              Email
                            </option>
                            <option
                              className=" color_gary fs_13"
                              value="WhatsApp"
                            >
                              WhatsApp
                            </option>
                            <option
                              className=" color_gary fs_13"
                              value="Direct call"
                            >
                              Direct call
                            </option>
                          </select>
                        </Col>
                      </Row>
                      <Row className="mb-2 ">
                        <Col className="col-12 col-md-6 mt-3">
                          {/* <input
                            required
                            type="text"
                            className="form-control rounded-0 ff_heebo py-3 px-3 border-0 bg-theme-primary"
                            placeholder="Accommodation preference"
                            value={formData?.accommodationPreference}
                            onChange={handleInputChange}
                            name="accommodationPreference"
                          /> */}
                          <select
                            value={formData?.accommodationPreference}
                            onChange={handleInputChange}
                            name="accommodationPreference"
                            className="elementor-field-textual elementor-size-sm w-100 rounded-0 fs_13 color_gary ff_heebo py-3 px-3 border-0 bg-theme-primary"
                          >
                            <option
                              className=" color_gary fs_13"
                              value="Accommodation preference"
                            >
                              Accommodation preference
                            </option>
                            <option
                              className=" color_gary fs_13"
                              value="Luxury"
                            >
                              Luxury
                            </option>
                            <option
                              className=" color_gary fs_13"
                              value="Ultra Luxe"
                            >
                              Ultra Luxe
                            </option>
                            <option
                              className=" color_gary fs_13"
                              value="Boutique/Family owned"
                            >
                              Boutique/Family owned
                            </option>
                            <option className=" color_gary fs_13" value="Other">
                              Other
                            </option>
                          </select>
                        </Col>
                        <Col className="col-12 col-md-6 mt-3">
                          {/* <input
                            required
                            type="text"
                            className="form-control rounded-0 ff_heebo py-3 px-3 border-0 bg-theme-primary"
                            placeholder="Budget"
                            value={formData?.budget}
                            onChange={handleInputChange}
                            name="budget"
                          /> */}
                          <select
                            value={formData?.budget}
                            onChange={handleInputChange}
                            name="budget"
                            id="form-field-field_c58d9d5"
                            className=" elementor-size-sm w-100 rounded-0 fs_13 color_gary ff_heebo py-3 px-3 border-0 bg-theme-primary"
                          >
                            <option
                              className=" color_gary fs_13"
                              value="Budget"
                            >
                              Budget
                            </option>
                            <option
                              className=" color_gary fs_13"
                              value="Up to $3,000"
                            >
                              Up to $3,000
                            </option>
                            <option
                              className=" color_gary fs_13"
                              value="$3,000-$5000"
                            >
                              $3,000-$5000
                            </option>
                            <option
                              className=" color_gary fs_13"
                              value="$5000-$7500"
                            >
                              $5000-$7500
                            </option>
                            <option
                              className=" color_gary fs_13"
                              value="$7500 - $10,000"
                            >
                              $7500 - $10,000
                            </option>
                            <option
                              className=" color_gary fs_13"
                              value="$10,000-$15,000"
                            >
                              $10,000-$15,000
                            </option>
                            <option
                              className=" color_gary fs_13"
                              value="$15,000-$25,000"
                            >
                              $15,000-$25,000
                            </option>
                            <option
                              className=" color_gary fs_13"
                              value="$25,000-$35,000"
                            >
                              $25,000-$35,000
                            </option>
                            <option
                              className=" color_gary fs_13"
                              value="35,000+"
                            >
                              35,000+
                            </option>
                            <option className=" color_gary fs_13" value="Other">
                              Other
                            </option>
                          </select>
                        </Col>
                      </Row>
                      <Row className="mb-2 mt-3">
                        <Col>
                          <select
                            value={formData?.flightsIncluded}
                            onChange={handleSelectChange}
                            name="flightsIncluded"
                            className="elementor-field-textual elementor-size-sm w-100 rounded-0 fs_13 color_gary ff_heebo py-3 px-3 border-0 bg-theme-primary"
                          >
                            <option className="color_gary fs_13" value="">
                              Are flights included in the budget?
                            </option>
                            <option className="color_gary fs_13" value="true">
                              Yes
                            </option>
                            <option className="color_gary fs_13" value="false">
                              No
                            </option>
                          </select>
                        </Col>
                      </Row>
                      <Row className="mb-2 mt-3">
                        <Col>
                          <input
                            required
                            type="text"
                            className="form-control rounded-0 ff_heebo py-3 px-3 border-0 bg-theme-primary"
                            placeholder="What airport/city will you be departing from (if you are needing airfare.)"
                            value={formData?.departingCity}
                            onChange={handleInputChange}
                            name="departingCity"
                          />
                        </Col>
                      </Row>
                      <Row className="mb-2">
                        <Col className="col-12 col-md-6 mt-3">
                          <input
                            required
                            type="text"
                            className="form-control rounded-0 ff_heebo py-3 px-3 border-0 bg-theme-primary"
                            placeholder="Number Of Adults"
                            value={formData?.adults}
                            onChange={handleInputChange}
                            name="adults"
                          />
                        </Col>
                        <Col className="col-12 col-md-6 mt-3">
                          <input
                            required
                            type="text"
                            className="form-control rounded-0 ff_heebo py-3 px-3 border-0 bg-theme-primary"
                            placeholder="Number Of Children"
                            value={formData?.children}
                            onChange={handleInputChange}
                            name="children"
                          />
                        </Col>
                      </Row>
                      <Row className="mb-2">
                        <Col className="col-12 col-md-6 mt-3">
                          <input
                            required
                            type="text"
                            className="form-control rounded-0 ff_heebo py-3 px-3 border-0 bg-theme-primary"
                            placeholder="From"
                            value={formData?.fromLocation}
                            onChange={handleInputChange}
                            name="fromLocation"
                          />
                        </Col>
                        <Col className="col-12 col-md-6 mt-3">
                          <input
                            required
                            type="text"
                            className="form-control rounded-0 ff_heebo py-3 px-3 border-0 bg-theme-primary"
                            placeholder="to"
                            value={formData?.toLocation}
                            onChange={handleInputChange}
                            name="toLocation"
                          />
                        </Col>
                      </Row>
                      {/* <Row className="mb-2">
                        <Col className="col-12 col-md-6 mt-3">
                          <input
                            type="text"
                            className="form-control rounded-0 ff_heebo py-3 px-3 border-0 bg-theme-primary"
                            placeholder="Phone"
                            value={formData?.name}
                            onChange={handleInputChange}
                            name="name"
                          />
                        </Col>
                        <Col className="col-12 col-md-6 mt-3">
                          <input
                            type="text"
                            className="form-control rounded-0 ff_heebo py-3 px-3 border-0 bg-theme-primary"
                            placeholder="email"
                          />
                        </Col>
                      </Row> */}
                      <Row className="mb-2 mt-3">
                        <Col>
                          <textarea
                            className="form-control rounded-0 ff_heebo pt-3 px-3 border-0 bg-theme-primary"
                            placeholder="Vacation Brief"
                            value={formData?.vacationBrief}
                            onChange={handleInputChange}
                            name="vacationBrief"
                          ></textarea>
                        </Col>
                      </Row>
                      <Row className="">
                        <Col className="d-flex justify-content-center">
                          <button
                            type="submit"
                            className="btn ff_heebo btn-primary px-5 mt-4"
                          >
                            Submit
                          </button>
                        </Col>
                      </Row>
                    </Container>
                  </form>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="my-5">
          <Container>
            <Row>
              <Col className="text-center">
                <div className="fs_15 ff_heebo">PLAN YOUR TRIP</div>
                <div className="fs_30 ff_heebo color_primary font-medium">
                  Our Popular Destinations
                </div>
              </Col>
            </Row>
            <Row className="mt-md-5 mt-3 flex-column flex-md-row">
              <Col className="d-flex justify-content-center mt-4 mt-md-0 justify-content-md-start align-items-center col-md-4 col-12">
                <div className="">
                  <img src={ImgView1} width="297" className="img-fluid" />
                </div>
              </Col>

              <Col className="col-md-4 justify-content-center d-flex mt-4 mt-md-0 col-12">
                <div className="">
                  <img src={ImgView2} width="456" className="img-fluid" />
                </div>
              </Col>

              <Col className="d-flex align-items-center mt-4 mt-md-0 justify-content-center justify-content-md-end col-md-4 col-12">
                <div>
                  <img src={ImgView1} width="297" className="img-fluid" />
                </div>
              </Col>
            </Row>

            <Row>
              <Col className="text-center mt-3">
                <a href="" className="text-brown ff_heebo fs_22">
                  View All &rarr;
                </a>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="my-4">
          <Container className="bg-section-primary rounded-right-top p-md-5 p-3">
            <Row>
              <Col className="text-center fs_30 text-brown ff_heebo font-medium">
                Top Reviews For Our Services
              </Col>
            </Row>
            <Row>
              <Col className="fs_22 my-3 font-normal ff_heebo">
                Travel changes you. As you move through this life and this
                world, you change things slightly, you leave marks behind,
                however small. And in return, life — and travel — leaves marks
                on you."
              </Col>
            </Row>

            <Row>
              <Col>
                <div className="text-end text-brown ff_heebo fs_30">
                  {" "}
                  <i>- Anthony Bourdain</i>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="py-md-5">
          <Container className="py-5">
            <Row>
              <Col className="col-md-5 col-12">
                <h3 className="text-end margin_bottom_space">
                  The Beginning of
                </h3>
                <img className="w-100" src={logo_img} alt="logo_img" />
              </Col>
              <Col className="fs_17 col-md-7 ff_heebo col-12">
                A small team, each on different continents, that share a deep
                love for travel. We saw this as an opportunity after years of
                hard work to share our passion with you! At Oryon our guest
                experience is preeminent to us. Therefore, in your customized
                itineraries we continue to highly recommend cities, hotels,
                restaurants, activities, and transfers that have been tried and
                tested by any one of our Oryon members.
              </Col>
            </Row>
            <Row>
              <Col className="fs_17 mt-3 ff_heebo col-12">
                We strive to reinvent travel with you in the best way possible
                and make your full travel dream & vision turn into a reality. As
                we, ourselves continue to travel in order to ensure the highest
                luxury and comfort experience for you, we hope that you will
                join us on once-in-a-lifetime, unique journeys, designed to meet
                your expectations.
              </Col>
            </Row>
          </Container>
        </section>

        <section className="py-4">
          <Container fluid className="position-relative">
            <div className="position-absolute d-md-block d-none bg-section-primary end-0 top-0 h-100 w-75"></div>
            <Container>
              <Row>
                <Col className="col-md-2 col-12 d-flex align-items-center justify-content-md-end justify-content-center position-relative z-index-10 p-0 me-n">
                  <img
                    src={ImgView3}
                    className="img-fluid rounded-left-top border-brown"
                  />
                </Col>
                <Col className="col-md-10 col-12 mt-4 mt-md-0">
                  <Container className="bg-section-primary rounded-left-top skew-left p-4 position-relative">
                    <div className="shape-long-rect position-absolute top-0 end-0"></div>
                    <Row>
                      <Col className="offset-md-2">
                        <div className="fs_30 font-medium mt-3 text-brown">
                          A Note From Founder
                        </div>
                        <div className="fs_17 mt-4 pt-4">
                          <p className="ff_heebo">
                            After years of being a part of the luxury industry,
                            and over 15 years of constant travel, growing
                            connections, and having collaborations on several
                            projects, Johanna has officially become a Luxury
                            Travel Expert, and with that title made the decision
                            of launching Oryon.
                          </p>
                          <p className="ff_heebo">
                            This is a great way for her to introduce a new way
                            of travel to her clientele, not to mention growing
                            her travel enthusiast family. She has mastered
                            detailed planning, consulting, and logistics, which
                            make it possible to pick out the best gems around
                            the globe according to preferences.
                          </p>
                          <p className="ff_heebo">
                            “Traveling spontaneously can be fun, but having
                            everything organized beforehand takes such a load of
                            ones shoulders that when you arrive at a given
                            destination you get to fully bask in the beauty of
                            the location and just have a worry-free time.” she
                            says.
                          </p>
                          <p className="ff_heebo">
                            “When I was traveling with friends, and my team,
                            before Oryon, we’ve reached out to a few other
                            agencies to have specific adventures organized for
                            us as leisure. Unfortunately we never found the
                            perfect match. The itineraries were all either
                            pre-made, or not what we had asked for.
                          </p>
                          <p className="ff_heebo">
                            We found it difficult to find something that suited
                            our liking and was reliable. As a Travel Advisor it
                            really all lays in detail, experience, and partners
                            to plan the highest standard seamless itinerary.
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </Col>
              </Row>
            </Container>
          </Container>
        </section>

        <section className="position-relative bg_newleeter min-vh-100">
          <img src={shed} className="w-100 position-absolute top-0 img_fluid" />
          <div className=" pt-5">
            {/* <img src={ImgSection1} className="w-100" /> */}
            <div className="position-absolute h-100 w-100 left-0 top-50 translate-middle-y  pt-5 d-flex align-items-center">
              <Container>
                <Row>
                  <Col className="col-md-6 d-flex align-items-center col-12">
                    <div className="rounded-4 bg-white p-5">
                      <div className="text-brown my-3">
                        <h4 className=" fs_24">
                          {" "}
                          Sign up for our monthly newsletter{" "}
                        </h4>
                      </div>
                      <div>
                        to find out more about our most popular destinations,
                        travel tips, inspirations and all the magic that comes
                        along with it
                      </div>

                      <div className="my-3">
                        <input
                          className="form-control-lg border-brown w-75"
                          placeholder="Enter Email"
                        ></input>
                      </div>
                      <button className="btn btn-primary px-5 py-2">
                        SUBSCRIBE
                      </button>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </section>
      </Container>
    </div>
  );
};

export default HomePage;
