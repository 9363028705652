import React from 'react'
import Image from "../../assets/images/country-hero-img.png"
import Layer from "../../assets/images/country-hero-under-layer.png"
import "./Country.css"
import { Container } from 'react-bootstrap'
const CountryHero = () => {
  return (
    <div className='vh-100 position-relative d-flex flex-column justify-content-center custom_margin_country_hero' >
    <img className='w-100 h-100 object-fit-cover position-absolute z-0' src={Image} alt="Image" />
    <img className='w-100 object-fit-cover position-absolute custom_translate_country_img z-0 bottom-0' src={Layer} alt="Image" />
    <Container>
        <h2 className='text-white ff_heebo  fs_41'>DESTINATIONS</h2>
        <p className='ff_heebo fw-normal text-white fs_21'>We aim to facilitate your ultimate travel <br /> experience in all ways possible</p>
    </Container>
</div>
  )
}

export default CountryHero