import React, {
  useEffect,
  useState,
} from 'react';

import { Link } from 'react-router-dom';

import couple_image from '../../assets/images/couple_image.png';

const CoupleReasons = ({ data }) => {
  const [Couple, setCouple] = useState([]);

  useEffect(() => {
    if (data && data.coupleReasons && Array.isArray(data.coupleReasons)) {
      setCouple(data.coupleReasons);
    }
  }, [data]);

  const slicedArray = Couple.slice(0, Couple.length - 1);

  return (
    <div className="pt-5 pb-0 pb-md-5">
      <div className="container">
        <h5 className="fs_30 ff_heebo fw-medium">Some native animals:</h5>
        <ul className="mt-3 ps-3">
          <li>
            <p className="fs_17 ff_heebo fw-normal">{data.animals}</p>
          </li>
        </ul>

        <h5 className="fs_30 ff_heebo mt-5 fw-medium">
          Couple reasons to visit
        </h5>
        <div className="d-md-flex gap-5 align-items-start">
          <div>
            <p>
              <ul className="mt-3 ps-3">
                {slicedArray.map((item, index) => (
                  <li key={index}>
                    <p
                      className="fs_17 ff_heebo fw-normal"
                      dangerouslySetInnerHTML={{ __html: item }}
                    />
                  </li>
                ))}
              </ul>
            </p>
            <Link to="/contact-us">
            <button className="mt-4 bg_dark_brown ff_heebo fw-medium px-5 py-2 rounded-2 border-0 text-white">
              Request more details
            </button>
            </Link>
          </div>

          <img
            width={178}
            className="mt-5"
            src={couple_image}
            alt="couple_image"
          />
        </div>
      </div>
    </div>
  );
};


export default CoupleReasons;
