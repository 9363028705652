import React, { useState } from "react";

import contactus_image from "../../assets/images/contactUs_image.png";
import { abcFunction } from "../../services/Continents";

const ContactUsHero = () => {
  const [formData, setformData] = useState({
    name: "",
    Email: "",
    Message: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setformData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const createForm = async (e) => {
    e.preventDefault();
    console.log("zkdjzjsdoijiosjdzsdvzsd");

    const data = {
      name: formData.name,
      Email: formData.Email,
      Message: formData.Message,
    };

    try {
      const res = await abcFunction(data);
      console.log(res);
      alert(
        "Thank you for connecting with us. We will get back to you shortly."
      );
      setformData({
        name: "",
        Email: "",
        Message: "",
      });
      window.scrollTo(0, 0);
      // createFormCallBack(res);
    } catch (error) {
      // toast.error("Failed to create lead. Please try again later.");
    }
  };
  return (
    <div className=" custom_bg pb-5 custom_margin_country_hero">
      <div className="position-relative pb-lg-5">
        <img
          className="w-100"
          height={523}
          src={contactus_image}
          alt="contactus_image"
        />
        <div className=" position-absolute start-50 translate-middle-x pt-lg-5 mt-lg-5 contact_position">
          <h4 className="mb-0 text-center fs_30 fw-medium ff_heebo text-white">
            CONTACT US
          </h4>
          <p className="mb-0 text-center mt-2 fs_16 fw-normal ff_heebo text-white">
            We aim to facilitate your ultimate travel
            <br className="d-lg-block d-none"></br> experience in all ways
            possible
          </p>
        </div>

        <div className="container">
          <div className="d-flex justify-content-center w-100 ">
            <div className="py-3"> </div>
            <div className=" p-md-5 p-4 contact_card custom_top_space">
              <form onSubmit={createForm}>
                <input
                  required
                  className="w-100 py-2 px-3 rounded-2 custom_input"
                  type="text"
                  placeholder="Name"
                  value={formData?.name}
                  onChange={handleInputChange}
                  name="name"
                />
                <input
                  required
                  className="w-100 py-2 px-3 mt-3 rounded-2 custom_input"
                  type="text"
                  placeholder="Email"
                  value={formData?.Email}
                  onChange={handleInputChange}
                  name="Email"
                />

                <textarea
                  required
                  name="textarea"
                  cols="20"
                  rows="5"
                  placeholder="Message"
                  className="w-100 py-2 px-3 mt-3 rounded-2 custom_input"
                  value={formData?.Message}
                  onChange={handleInputChange}
                  name="Message"
                />
                <button className="send_btn w-100 mt-4 rounded-2 border-0 py-2 fs_17 fw-normal ff_heebo text-white ">
                  SEND
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUsHero;
