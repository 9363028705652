import React, { useEffect } from 'react';

import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import arrow from '../../assets/images/arrow.png';
import { getContinentsFunction } from '../../services/Continents';

const MakeTripe = () => {
  const [data, setContinents] = React.useState([]);

  useEffect(() => {
    dataFunction()
  },[])

  const dataFunction = async ()=>{
    const res = await getContinentsFunction()
    setContinents(res.data.data.data)
  }
  // const data = [
  //   { image: Image1, title: "Europe" },
  //   { image: Image2, title: "America" },
  //   { image: Image3, title: "Asia" },
  //   { image: Image4, title: "Africa" },
  //   { image: Image5, title: "Australia" },
  //   { image: Image6, title: "Oceania" },
  // ];
  return (
    <Container className="py-5">
      <p className="ff_heebo fw-normal fs_15 text-center">PLAN YOUR TRIP</p>
      <h3 className="color_primary ff_heebo fw-semibold fs_30 text-center">
        Get Inspired
      </h3>
      <div className="row mt-5">
        {data.map((item, index) => {
          return (
            <div className="col-md-4 col-sm-6 mt-4" key={index}>
              <Link
                    className="d-flex justify-content-between w-100 align-items-center"
                    to={`/destinations/${item.name}`}
                  >
              <div className="position-relative cursor_pointer">
                <div className="d-flex align-items-center justify-content-between w-100 px-3 overlay">
                  
                    <p className="mb-0 text-black fw-medium fs_15">
                      {item.name}
                    </p>
                    <a href="#">
                      <img width={29} src={arrow} alt="" />
                    </a>
                </div>
                <div className='overlay1 position-absolute'></div>
                <img className="w-100 rounded-3 " src={`${process.env.REACT_APP_IMAGE_URL}/${item.smallImage}`} alt="Image" />
              </div>
              </Link>
            </div>
          );
        })}
      </div>
    </Container>
  );
};

export default MakeTripe;
