import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';

import {
  Route,
  Routes,
} from 'react-router-dom';

import Footer from './components/common/Footer';
import OryonNavbar from './components/common/OryonNavbar';
import AboutUsPage from './components/pages/AboutUsPage';
import ContactUsPage from './components/pages/ContactUsPage';
import Country from './components/pages/Country';
import DestinationDetails from './components/pages/DestinationDetails';
import Destinations from './components/pages/Destinations';
import HomePage from './components/pages/HomePage';

function App() {
  return (
    <div>
      <OryonNavbar />

      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/contact-us" element={<ContactUsPage />} />
        <Route path="/about-us" element={<AboutUsPage />} />
        <Route path="/country" element={<Country />} />
        <Route path="/destinations/:name" element={<Destinations />} />
        <Route path="/destination-details/:id" element={<DestinationDetails />} />

      </Routes>
      <Footer />
    </div>
  );
}

export default App;
