import React from "react";
import footer_logo from "../../assets/images/footer_logo.png";
import sms_icon from "../../assets/images/sms_icon.png";
import whatshap_icon from "../../assets/images/whatshp_icon.png";
import insta_icon from "../../assets/images/insta_icon.png";
import pay_icon from "../../assets/images/pay_icon.png";

const Footer = () => {
  return (
    <div className="pt-5 footer_background">
      <div className="container">
        <a href="#">
          <img width={92} src={footer_logo} alt="footer_logo" />
        </a>
        <div className="row justify-content-between mt-4">
          <div className="col-lg-3 col-sm-6 mt-4">
            <h5 className="mb-0 fs_22 ff_heebo fw-medium text-white">
              Discover a new way to travel!
            </h5>
            <p className=" mt-3 fs_15 ff_heebo fw-light text-white">
              Our team at Oryon specializes in luxury travel and offers the most
              unique travel services. Our agents excel in creating
              one-of-a-kind, customized, breathtaking experiences of your
              choice, and fitted to your liking and vision.
            </p>
          </div>
          <div className="col-lg-3 col-sm-6 mt-4">
            <h4 className="fs_22 mb-0 ff_heebo fw-medium text-white">
              Quick Contact
            </h4>
            <div className="d-flex mt-3 align-items-center gap-2">
              <img width={15} src={sms_icon} alt="sms_icon" />
              <a
                className=" text-white fw-light"
                href="mailto:info@oryon.stagingwebsite.space"
              >
                info@oryon.stagingwebsite.space
              </a>
            </div>
            <div className="d-flex mt-2 align-items-center gap-2">
              <img width={15} src={whatshap_icon} alt="sms_icon" />
              <a className=" text-white fw-light" href="tell:+971 56 776 0222">
                +971 56 776 0222
              </a>
            </div>
            <div className="d-flex mt-2 align-items-center gap-2">
              <img width={15} src={insta_icon} alt="sms_icon" />
              <a className=" text-white fw-light" href="#">
                @travel_oryon
              </a>
            </div>
          </div>
          <div className="col-lg-2  col-md-4 col-sm-6 mt-4">
            <h4 className="fs_22 mb-0 ff_heebo fw-medium text-white">
              Destinations
            </h4>
            <ul className="ps-3 mt-3">
              <li className="text-white mt-2">
                <a
                  className="opacity_color fs_15 ff_heebo fw-light text-white"
                  href="#"
                >
                  Europe
                </a>
              </li>
              <li className="text-white mt-2">
                <a
                  className="opacity_color fs_15 ff_heebo fw-light text-white"
                  href="#"
                >
                  Africa
                </a>
              </li>
              <li className="text-white mt-2">
                <a
                  className="opacity_color fs_15 ff_heebo fw-light text-white"
                  href="#"
                >
                  America
                </a>
              </li>
              <li className="text-white mt-2">
                <a
                  className="opacity_color fs_15 ff_heebo fw-light text-white"
                  href="#"
                >
                  Asia Middle East
                </a>
              </li>
              <li className="text-white mt-2">
                <a
                  className="opacity_color fs_15 ff_heebo fw-light text-white"
                  href="#"
                >
                  Australia
                </a>
              </li>
              <li className="text-white mt-2">
                <a
                  className="opacity_color fs_15 ff_heebo fw-light text-white"
                  href="#"
                >
                  Oceania
                </a>
              </li>
            </ul>
          </div>
          <div className="col-lg-3 col-sm-6 mt-4">
            <h4 className="fs_22 mb-0 ff_heebo fw-medium text-white">
              We accept
            </h4>
            <img className="w-100 mt-3" src={pay_icon} alt="pay_icon" />
          </div>
        </div>
      </div>
      <div className="custom_border mt-5"></div>
      <p className=" fw-light opacity_color text-center py-3 mb-0">
        ⓒ2024 Oryon Travels&nbsp;&nbsp;
        <span className=" color_gary">All Rights Reserved</span>
      </p>
    </div>
  );
};

export default Footer;
