import React from 'react';

import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import arrow from '../../assets/images/arrow.png';

const WesternCountryGallery = ({index , destination}) => {
  console.log("destination",destination.webcountry);
  const data = destination.webcountry
  console.log(index);
  // const data = [
  //   { image: Image1, title: "Europe" },
  //   { image: Image2, title: "America" },
  //   { image: Image3, title: "Asia" },
  //   { image: Image4, title: "Africa" },
  //   { image: Image5, title: "Australia" },
  //   { image: Image6, title: "Oceania" },
  // ];
  return (
    <Container className="pb-5 mt-5">
     
      <h3 className="color_primary  mt-5 ff_heebo fw-semibold fs_30 text-center">
        {destination.name}
      </h3>
      <div className="row ">
        {data.map((item, index) => {
          return (
            <div className="col-md-4 col-sm-6 mt-4" key={index}>
               <Link to={`/destination-details/${item._id}`}>
              <div className="position-relative cursor_pointer">
             
                <div className="d-flex align-items-center w-100 px-3 overlay">
                  <div className="d-flex justify-content-between w-100 align-items-center">
                    <p className="mb-0 text-black">{item.name}</p>
                      <img width={29} src={arrow} alt="" />               </div>
                </div> 
                
                <div className='overlay1 position-absolute'></div>
                <img className="w-100 rounded-3" src={`${process.env.REACT_APP_IMAGE_URL}${item.mainImage}`} alt="Image" />
              </div>
              </Link>
            </div>
          );
        })}
      </div>
    </Container>
  );
};

export default WesternCountryGallery;
