import { useEffect, useState } from "react";

import moment from "moment-timezone";

const CountryTime = ({ data }) => {
  const locationName = data.name;
  const [coordinates, setCoordinates] = useState({ lat: null, lng: null });
  const [name, setName] = useState("");
  const [currentTime, setCurrentTime] = useState("");
  const [TimeZone, setTimeZone] = useState("");
  const [time, setTime] = useState("");
  const [date, setDate] = useState("");
  const [day, setDay] = useState("");

  useEffect(() => {
    const getLocationCoordinates = async () => {
      try {
        const response = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
            locationName
          )}&key=AIzaSyASdpNTLU5ZEqnl9eG0yP3n8GHmXGR0igM`
        );
        // console.log(response);
        const data = await response.json();
        console.log("data data data data", data);
        if (data.status === "OK" && data.results && data.results.length > 0) {
          const { lat, lng } = data.results[0].geometry.location;
          const name = data.results[0].formatted_address;
          setCoordinates({ lat, lng });

          setName({});

          const urlW = `https://api.openweathermap.org/data/2.5/forecast?lat=${lat}&lon=${lng}&appid=5c1d3b3059e6fab9ef0493c4d30eb82e&units=metric`;
          const responseWeather = await fetch(urlW);
          const dataW = await responseWeather.json();

          setTimeZone(dataW.city.timezone);
        } else {
          console.error("No coordinates found for the location:", locationName);
        }
      } catch (error) {
        console.error("Error fetching coordinates:", error);
      }
    };

    getLocationCoordinates();
  }, [locationName]);
  // console.log(coordinates);

  const timezoneOffset = TimeZone;

  // console.log("TimeZone TimeZone", TimeZone);

  // Update the current time every second
  useEffect(() => {
    const intervalId = setInterval(() => {
      const newTime = moment().utcOffset(timezoneOffset / 60);
      const formattedTime = newTime.format("h:mm:ss A");
      const formattedDate = newTime.format(" DD-MMM-YYYY");
      const formattedDay = newTime.format("dddd");

      setCurrentTime(newTime);
      setTime(formattedTime);
      setDate(formattedDate);
      setDay(formattedDay);
    }, 1000);

    // Clean up interval on component unmount
    return () => clearInterval(intervalId);
  }, [timezoneOffset]);

  // console.log("coordinates", coordinates.lat)

  const dateObj = moment(time, "h:mm:ss A");
  const hours = dateObj.hour();
  const minutes = dateObj.minute();
  const seconds = dateObj.second();

  const hourRotation = (hours % 12) * 30 + minutes * 0.5;
  const minuteRotation = minutes * 6 + seconds * 0.1;

  const clockStyle = {
    width: "300px",
    height: "300px",
    borderRadius: "50%",
    position: "relative",
    boxShadow: "0 2px 30px rgba(0, 0, 0, 0.2)",
    fontSize: "24px",
    color: "#444",
    textAlign: "center",
    zIndex: "1",
    background: "white",
  };

  return (
    <div className="mt-5">
      <div className="container">
        <div className="row">
          <div className="col-md-6 p-0 ">
            <div className="bg-light w-100 h-100 p-4 d-flex align-items-center flex-column justify-content-center rounded-left-3  rounded-top-3">
              <div className="">
                {" "}
                <p className="ff_heebo fw-bold text-black fs_33">
                  Time in {data.name}
                </p>
                <div className="d-flex justify-content-center position-relative">
                  <div className="clock position-relative" style={clockStyle}>
                    <div
                      className="hour_hand"
                      style={{
                        transform: `rotate(${hourRotation}deg)`,
                      }}
                    />
                    <div
                      className="min_hand"
                      style={{
                        transform: `rotate(${minuteRotation}deg)`,
                      }}
                    />
                    <span className="twelve">12</span>
                    <span className="one">1</span>
                    <span className="two">2</span>
                    <span className="three">3</span>
                    <span className="four">4</span>
                    <span className="five">5</span>
                    <span className="six">6</span>
                    <span className="seven">7</span>
                    <span className="eight">8</span>
                    <span className="nine">9</span>
                    <span className="ten">10</span>
                    <span className="eleven">11</span>
                  </div>
                </div>
                <p className="fs_24 mt-3 fw-bold ff_heebo text-center">
                  {time}
                </p>
                <p className="fs_22 ff_heebo text-center">
                  {day}, {date}
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 rounded-md-right-3 p-0 rounded-md-bottom-3 h-100 rounded-bottom-3 p-0 rounded-left-3">
            <div className="h-100">
              <img
                className="w-100 object-fit-cover"
                src={`${process.env.REACT_APP_IMAGE_URL}${data.countryImage}`}
                alt="image"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CountryTime;
