import './Destination.css';

import React from 'react';

const DestinationHero = ({name,image}) => {
  return (
    <div className='custom_top_margin position-relative'>
      <img className='w-100 filter' src={`${process.env.REACT_APP_IMAGE_URL}/${image}`} alt="image" />
      <button className='position-absolute bottom-0 start-50 custom_translate_btn country_btn fs_45 ff_heebo fw-semibold'>{name}</button>
     
    </div>
  )
}

export default DestinationHero