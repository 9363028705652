import React, { useState } from 'react';

import Container from 'react-bootstrap/Container';
import { Link } from 'react-router-dom';

import cross_icon from '../../assets/images/cross_icon.png';
import Logo from '../../assets/images/oryon 1.png';
import Flag from '../../assets/images/uk_flag.svg';

const OryonNavbar = () => {
  const [sidebarVisible, setSidebarVisible] = useState(false);

  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };
  return (
    <div>
      <Container
        fluid
        className="bg-theme p-0 position-fixed top-0 left-0 w-100 top-navbar-container"
      >
        <Container className="py-3">
          <div className="d-flex justify-content-between align-items-center">
            <div className="align-items-center d-flex">
              <Link to="/">
                {" "}
                <img src={Logo} width={90} />
              </Link>
            </div>
            <div>
              <div className=" d-md-flex  gap-xl-5 gap-4 d-none justify-content-end align-items-center h-100">
                <Link
                  to="/"
                  className="navbar-link after_line position-relative flex-fill text-decoration-none text-black"
                >
                  Home
                </Link>
                {/* <Link
                  to="/country"
                  className="navbar-link flex-fill after_line position-relative text-decoration-none text-black"
                >
                  Country
                </Link> */}
                <Link
                  to="/about-us"
                  className="navbar-link flex-fill after_line position-relative text-decoration-none text-black"
                >
                  about us
                </Link>
                <Link
                  to="/country"
                  className="navbar-link flex-fill after_line position-relative text-decoration-none text-black"
                >
                  Destinations
                </Link>
                <Link
                  to="/contact-us"
                  className="navbar-link flex-fill after_line position-relative text-decoration-none text-black"
                >
                  Contact Us
                </Link>
                <a
                  href="#"
                  className="navbar-link flex-fill text-decoration-none text-black"
                >
                  <img src={Flag} />
                </a>
              </div>
            </div>
            <div
              className={`navmanu d-md-none ms-auto position-relative custom_zindex d-block burger  ${
                sidebarVisible ? "active" : ""
              }`}
              onClick={toggleSidebar}
            >
              <div></div>
              <div className=""></div>
              <div></div>
            </div>
            {sidebarVisible && (
              <div
                className={`sidebar d-flex flex-column justify-content-center align-items-center  ${
                  sidebarVisible ? "show " : "hide"
                }`}
              >
                <img
                  onClick={toggleSidebar}
                  className=" cursor_pointer position-absolute cross_icon d-md-block d-none"
                  width={30}
                  src={cross_icon}
                  alt="cross_icon"
                />
                <div className="d-block align-items-center gap-5 ">
                  <ul className="d-flex flex-column  mb-0 ps-0 text-center">
                    <Link
                      to="/"
                      className="navbar-link flex-fill text-decoration-none text-white ff_heebo mt-3"
                    >
                      Home
                    </Link>
                    {/* <Link
                      to="/country"
                      className="navbar-link flex-fill text-decoration-none text-white ff_heebo mt-3"
                    >
                      Country
                    </Link> */}
                    <Link
                      to="/about-us"
                      className="navbar-link flex-fill text-decoration-none text-white ff_heebo mt-3"
                    >
                      about us
                    </Link>
                    <Link
                      // to="/destinations"
                      to="/country"
                      className="navbar-link flex-fill text-decoration-none text-white ff_heebo mt-3"
                    >
                      Destinations
                    </Link>
                    <Link
                      to="/contact-us"
                      className="navbar-link flex-fill text-decoration-none text-white ff_heebo mt-3"
                    >
                      Contact Us
                    </Link>
                    <a
                      href="#"
                      className="navbar-link flex-fill text-decoration-none text-white ff_heebo mt-3"
                    >
                      <img src={Flag} />
                    </a>
                  </ul>
                </div>
              </div>
            )}
          </div>
        </Container>
      </Container>
    </div>
  );
};

export default OryonNavbar;
