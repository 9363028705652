import React from "react";
import aboutus_image from "../../assets/images/aboutus_image.png";
import about_position_image from "../../assets/images/about_position_image.png";
import about_icon from "../../assets/images/about_icon.png";
import about_icon1 from "../../assets/images/about_icon1.png";
import about_icon2 from "../../assets/images/about_icon2.png";
import about_icon3 from "../../assets/images/about_icon3.png";
import about_icon4 from "../../assets/images/about_icon4.png";
import about_icon5 from "../../assets/images/about_icon5.png";

const AboutUsHero = () => {
  return (
    <div className=" custom_bg pb-5 custom_margin_country_hero">
      <div className="position-relative">
        <img
          className="w-100"
          height={503}
          src={aboutus_image}
          alt="aboutus_image"
        />

        <div className=" position-absolute top-50 translate-middle about_position ">
          <h4 className="mb-0  fs_30 fw-medium ff_heebo text-white">
            ABOUT US
          </h4>
          <p className="mb-0  mt-2 fs_16 fw-normal ff_heebo text-white">
            We aim to facilitate your ultimate travel<br></br> experience in all
            ways possible
          </p>
        </div>
      </div>
      <div className="container ">
        <div className="row">
          <div className="col-lg-5 pt-4">
            <img
              className="w-100 image_margin "
              src={about_position_image}
              alt="about_position_image"
            />
          </div>
          <div className="col-lg-7">
            <div className="about_card image_margintop w-100 custom_pading pe-4">
              <h4 className=" fs_30 fw-medium ff_heebo">
                Discover a new way to<br className="d-lg-block d-none"></br>{" "}
                travel!
              </h4>
              <p className=" fs_16 fw-normal ff_heebo custom_linehight">
                Our team at Oryon specializes in luxury travel and offers the
                most unique travel services. Our agents excel in creating
                one-of-a-kind, customized, breathtaking experiences of your
                choice, and fitted to your liking and vision.
              </p>
              <h4 className=" fs_30 fw-medium ff_heebo">
                We make your dream a<br className="d-lg-block d-none"></br>{" "}
                reality!
              </h4>
              <p className=" fs_16 fw-normal ff_heebo custom_linehight">
                Nothing compares to setting foot in some incredible, extravagant
                locations during any chosen season, on any continent.
              </p>
              <p className=" fs_16 fw-normal ff_heebo custom_linehight">
                Once the form on our home page is filled out, we will reach out
                to you through your selected means to have a one-on-one
                itinerary designed solely for you.ravagant locations during any
                chosen season, on any continent.
              </p>
              <p className=" fs_16 fw-normal ff_heebo custom_linehight">
                We organize and arrange everything for you from A to Z. From the
                minute you land to the minute you depart; including
                accommodation, transport, and activities in your places of
                interest, and according to the pace of travel desired.
              </p>
              <p className=" fs_16 fw-normal ff_heebo custom_linehight">
                In case of a preference for a lower budget/ more classic travel
                plan, or on occasion of no desire to have an itinerary planned,
                feel free to submit the form regardless, with a note in the
                comment section, and we will go forward accordingly.
              </p>
            </div>
          </div>
        </div>
        <h4 className="mt-5 pt-4 fs_30 fw-medium ff_heebo color_primary text-center">
          Why choose Oryon
        </h4>
        <div className="row mt-4">
          <div className="col-lg-2 col-sm-4 col-6">
            <div className=" bg_dark_brown rounded-2 d-flex justify-content-center align-items-center py-4">
              <img src={about_icon} alt="about_icon" />
            </div>
            <p className="text-center mt-2 fs_24 fw-normal">Honeymoons</p>
          </div>
          <div className="col-lg-2 col-sm-4 col-6">
            <div className=" bg_dark_brown rounded-2 d-flex justify-content-center align-items-center py-4">
              <img src={about_icon1} alt="about_icon" />
            </div>
            <p className="text-center mt-2 fs_24 fw-normal">Sun</p>
          </div>
          <div className="col-lg-2 col-sm-4 col-6">
            <div className=" bg_dark_brown rounded-2 d-flex justify-content-center align-items-center py-4">
              <img src={about_icon2} alt="about_icon" />
            </div>
            <p className="text-center mt-2 fs_24 fw-normal">Ski</p>
          </div>
          <div className="col-lg-2 col-sm-4 col-6">
            <div className=" bg_dark_brown rounded-2 d-flex justify-content-center align-items-center py-4">
              <img src={about_icon3} alt="about_icon" />
            </div>
            <p className="text-center mt-2 fs_24 fw-normal">Safari</p>
          </div>
          <div className="col-lg-2 col-sm-4 col-6">
            <div className=" bg_dark_brown rounded-2 d-flex justify-content-center align-items-center py-4">
              <img src={about_icon4} alt="about_icon" />
            </div>
            <p className="text-center mt-2 fs_24 fw-normal">Cruises</p>
          </div>
          <div className="col-lg-2 col-sm-4 col-6">
            <div className=" bg_dark_brown rounded-2 d-flex justify-content-center align-items-center py-4">
              <img src={about_icon5} alt="about_icon" />
            </div>
            <p className="text-center mt-2 fs_24 fw-normal">Groups</p>
          </div>
        </div>
      </div>
      <div className="bg_light_brown py-5 mt-5">
        <p className="text-center fw-normal fs_16 ff_heebo">
          "Travel changes you. As you move through this life and this world, you
          change things slightly, you leave marks behind, however<br></br>{" "}
          small. And in return, life — and travel — leaves marks on you.
          <br></br>"{" "}
          <span className=" fw-bold ff_heebo  color_primary">
            - Anthony Bourdain
          </span>
        </p>
      </div>
    </div>
  );
};

export default AboutUsHero;
