import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import ImgSection1 from "../../assets/images/section-img-bg.png";
import Layer from "../../assets/images/signup-top-layer.png";
import "./Country.css";
const SignUp = () => {
  return (
    <section className="position-relative mt-5 pt-5">
      <img
        className="position-absolute top-0 mt-5 pt-4 start-0 w-100 start-50 translate-middle"
        src={Layer}
        alt="layer"
      />
      <img src={ImgSection1} className="w-100 img-fluid" />
      <div className="position-absolute d-md-flex h-100 w-100 left-0 top-0 d-flex align-items-center">
        <Container>
          <Row>
            <Col className="col-md-6 d-flex align-items-center col-12">
              <div className="rounded-4 bg-white p-5">
                <div className="text-brown my-3 text-capitalize ff_heebo fw-noraml fs_19">
                  Sign up for our monthly newsletter{" "}
                </div>
                <div className="my-3 text-capitalize ff_heebo fw-noraml font_17">
                  to find out more about our most popular destinations, travel
                  tips, inspirations and all the magic that comes along with it
                </div>

                <div className="my-3">
                  <input
                    className="form-control-lg border-brown w-75"
                    placeholder="Enter Email"
                  ></input>
                </div>
                <button className="btn btn-primary px-5 py-2 ">
                  SUBSCRIBE
                </button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
};

export default SignUp;
