import React, {
  useEffect,
  useState,
} from 'react';

import { useLocation } from 'react-router-dom';

import { getRegionFunction } from '../../services/Continents';
import DestinationHero from '../destinations/DestinationHero';
import WesternCountryGallery from '../destinations/WesternCountryGallery';

const Destinations = () => {
  const [data, setContinents] = useState([]);
  const [dataContinents, setContinentsData] = useState('');
  const [ContinentsName, setContinentsName] = useState('');
  const [ContinentsImage, setContinentsImage] = useState('');
  const [name, setName] = useState('');

  const location = useLocation();

  useEffect(() => {
    const getContinentNameFromUrl = () => {
      const urlParts = location.pathname.split('/');
      const continentName = urlParts[urlParts.length - 1]; // Last part of the URL
      return continentName;
    };

    const continentName = getContinentNameFromUrl();
    setName(continentName);
  }, [location]);

  useEffect(() => {
    if (name) {
      restionData(name);
    }
  }, [name]);

  

  const restionData = async (name) => {
    try {
      const res = await getRegionFunction(name);
      console.log(res.data.data.data);
      setContinents(res.data.data.data[0].region);
      setContinentsData(res.data.data.data[0].title);
      setContinentsName(res.data.data.data[0].name);
      setContinentsImage(res.data.data.data[0].mainImage);
    } catch (error) {
      console.error('Error fetching region data:', error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <>
      <DestinationHero name={ContinentsName} image={ContinentsImage} />
      <p className="ff_heebo fw-normal pt-5 fs_15 text-center" style={{ marginTop: '50px' }}>
        {dataContinents}
      </p>
      {data.map((val, index) => (
        <WesternCountryGallery key={index} index={index} destination={val} />
      ))}
    </>
  );
};

export default Destinations;
